//@author: sara
import {
    CurrencyFormatPipe,
    DateFormatPipe,
    TimeFormatPipe,
    DurationPipe,
    HTMLPipe,
    SafeLink,
    SortByPipe,
} from "./s25.pipes";
import { NgModule } from "@angular/core";

@NgModule({
    declarations: [SafeLink, DurationPipe, SortByPipe, DateFormatPipe, TimeFormatPipe, HTMLPipe, CurrencyFormatPipe],
    exports: [SafeLink, DurationPipe, SortByPipe, DateFormatPipe, TimeFormatPipe, HTMLPipe, CurrencyFormatPipe],
    providers: [SafeLink, DurationPipe, SortByPipe, DateFormatPipe, TimeFormatPipe, HTMLPipe, CurrencyFormatPipe],
})
export class S25PipesModule {}
