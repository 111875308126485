//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { UserDefaultsComponent } from "./user.defaults.component";
import { S25ToggleButtonModule } from "../../s25-toggle-button/s25.toggle.button.module";
import { S25CheckboxModule } from "../../s25-checkbox/s25.checkbox.module";

@NgModule({
    declarations: [UserDefaultsComponent],
    imports: [CommonModule, FormsModule, S25ToggleButtonModule, S25CheckboxModule],
    providers: [UserDefaultsComponent],
})
export class UserDefaultsModule {
    constructor() {}
}
