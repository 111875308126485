//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Sanitizer,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { PreferenceService } from "../../../services/preference.service";
import { Eval25Service } from "../../../services/eval25.service";

@TypeManagerDecorator("system-defaults")
@Component({
    selector: "system-defaults",
    template: `
        @if (!this.initLoading) {
            <div>
                <div class="c-margin-bottom--single">
                    <h2 class="c-margin-bottom--single">System Defaults</h2>
                    <label for="loginRequired" class="ngBold c-margin-bottom--quarter"> Sign In Required </label>
                    <p class="ngFinePrint c-margin-bottom--half">
                        Setting that determines whether users are required to sign in to access 25Live, or whether they
                        can browse as a guest using the permissions of the viewer seat user.
                    </p>
                    <div id="loginRequired">
                        <s25-toggle-button
                            (modelValueChange)="this.onChange($event, 'login')"
                            id="login"
                            [modelValue]="this.loginRequired"
                            [trueLabel]="trueLabel"
                            [falseLabel]="falseLabel"
                        ></s25-toggle-button>
                    </div>
                </div>
                <div class="c-margin-bottom--single">
                    <label for="signInMsg" class="ngBold c-margin-bottom--quarter"> Sign In Message </label>
                    <p class="ngFinePrint c-margin-bottom--half">
                        The message to appear at the bottom of the Sign In window.
                    </p>
                    <div class="c-margin-top--quarter">
                        <s25-ng-rich-text-editor
                            [modelValue]="this.signInMsg"
                            autoFocus="false"
                            (onEditorContentChange)="this.onChangeMsg($event, 'signInMsg')"
                        ></s25-ng-rich-text-editor>
                    </div>
                </div>
                <div>
                    <label for="searchUser" class="ngBold c-margin-bottom--quarter"> Public Search Username </label>
                    <p class="ngFinePrint c-margin-bottom--half">
                        The username of the contact whose saved searches are automatically made "public" so they can be
                        run by all users.
                    </p>
                    <input
                        type="text"
                        id="searchUser"
                        [(ngModel)]="this.searchUser"
                        name="searchUser"
                        class="c-input"
                        [value]="this.searchUser"
                        required
                        (change)="this.onChange($event, 'searchUser')"
                    />
                    <p class="ngRed">
                        @if (this.searchUser.trim() === "") {
                            <span>Please enter a user name.</span>
                        }
                    </p>
                </div>
                <div>
                    <label for="aiChatbotName" class="ngBold c-margin-bottom--quarter"> AI Chatbot Name </label>
                    <p class="ngFinePrint c-margin-bottom--half">
                        The name all AI chatbots will introduce themselves as.
                    </p>
                    <input
                        type="text"
                        id="aiChatbotName"
                        [(ngModel)]="this.aiChatbotName"
                        name="aiChatbotName"
                        class="c-input"
                        [value]="this.aiChatbotName"
                        required
                        (ngModelChange)="this.validCustomAiName()"
                    />
                    <p class="ngRed">
                        @if (!this.isValidAiName) {
                            <span>Custom names can only contain letters, numbers, underscores, and dashes.</span>
                        }
                    </p>
                </div>
                <div class="c-margin-top--quarter">
                    <label for="logoLink" class="ngBold c-margin-bottom--quarter"> Header Logo Link </label>
                    <p class="ngFinePrint c-margin-bottom--half">
                        If you provide a link here (Example: http://www.yourschool.edu), clicking on your site header
                        will open that link in a new window. By default, your site header image is not linked and
                        clicking on it will do nothing.
                    </p>
                    <input
                        type="text"
                        id="logoLink"
                        [(ngModel)]="this.logoLink"
                        class="c-input"
                        name="logoLink"
                        (ngModelChange)="this.validLogoLink()"
                    />
                    @if (!this.isValidLogoLink) {
                        <p class="ngRed">Please enter a valid link.</p>
                    }
                </div>
                <div class="c-margin-bottom--single c-margin-top--quarter">
                    <label for="eventDisplay" class="ngBold c-margin-bottom--quarter"> Event Display Field </label>
                    <p class="ngFinePrint c-margin-bottom--half">
                        Setting for whether the Event Name or Event Title is primarily used for display purposes
                        throughout 25Live.
                    </p>
                    <div id="eventDisplay">
                        <s25-toggle-button
                            id="event"
                            (modelValueChange)="this.onChange($event, 'event')"
                            [modelValue]="this.eventDisplay"
                            [trueLabel]="eventTrueLabel"
                            [falseLabel]="eventFalseLabel"
                        ></s25-toggle-button>
                    </div>
                </div>
                @if (hasEval25License) {
                    <div class="c-margin-bottom--single c-margin-top--quarter">
                        <label for="eval25Display" class="ngBold c-margin-bottom--quarter">
                            Display Location Satisfaction Scores from Eval25</label
                        >
                        <p class="ngFinePrint c-margin-bottom--half">
                            Should the Location Satisfaction Score from Eval25 display on location details and in the
                            event form when it is available.
                        </p>
                        <div id="eventDisplay">
                            <s25-toggle-button
                                id="event"
                                (modelValueChange)="this.onChange($event, 'eval25')"
                                [modelValue]="this.eval25Display"
                                [trueLabel]="'Yes'"
                                [falseLabel]="'No'"
                            ></s25-toggle-button>
                        </div>
                    </div>
                }
                <div class="c-displayBlock c-padding-bottom--double">
                    <button
                        class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                        (click)="save()"
                        [disabled]="this.searchUser.trim() === '' || !this.isValidLogoLink || !this.isValidAiName"
                    >
                        {{ this.loading ? "Saving...." : "Save" }}
                    </button>
                    <button class="aw-button aw-button--outline c-margin-top--single" (click)="cancel()">Cancel</button>
                </div>
                @if (this.message && this.message !== "") {
                    <div class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single" role="alert">
                        <div class="cn-alert__icon cn-icon" name="alert--info">
                            <svg class="cn-svg-icon" role="img">
                                <title>Success Alert</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                                ></use>
                            </svg>
                        </div>
                        <div class="cn-alert__label">
                            <span>{{ this.message }}</span>
                        </div>
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemDefaultsComponent implements OnInit {
    @Input() falseLabel: string = "Optional";
    @Input() trueLabel: string = "Required";
    @Input() eventFalseLabel: string = "Event Title";
    @Input() eventTrueLabel: string = "Event Name";
    @Input() showFalseLabel: string = "Hide requests";
    @Input() showTrueLabel: string = "Show requests";
    @Output() modelValueChange = new EventEmitter();
    @Output() onEditorContentChange = new EventEmitter();

    initLoading: boolean = true;
    loading: boolean = false;
    loginRequired: boolean = false;
    signInMsg: string = "";
    searchUser: string = "";
    aiChatbotName: string = "";
    eventDisplay: boolean = false;
    eval25Display: boolean = false;
    hasEval25License: boolean;
    logoLink: string = "";
    eventState: any;
    eventStateOptions = [
        { val: 0, txt: "Draft" },
        { val: 1, txt: "Tentative" },
        { val: 3, txt: "Confirmed" },
    ];

    initLoginRequired: boolean = false;
    initSignInMsg: string = "";
    initSearchUser: string = "";
    initAiChatbotName: string = "";
    initEventDisplay: boolean = false;
    initEval25Display: boolean = false;

    isValidAiName: boolean = true;
    isValidLogoLink: boolean = true;
    initLogoLink: string = "";
    message: string = "";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private sanitizer: Sanitizer,
    ) {}

    onChange($event: any, field: any) {
        if (field === "login") {
            this.modelValueChange.emit(this.loginRequired);
            this.loginRequired = $event;
        } else if (field === "eval25") {
            this.modelValueChange.emit(this.eval25Display);
            this.eval25Display = $event;
        } else {
            this.modelValueChange.emit(this.eventDisplay);
            this.eventDisplay = $event;
        }
        this.cd.detectChanges();
    }

    validCustomAiName() {
        this.isValidAiName = new RegExp(/^[a-zA-Z0-9_-]+$/).test(this.aiChatbotName);
        this.cd.detectChanges();
    }

    validLogoLink() {
        this.logoLink === ""
            ? (this.isValidLogoLink = true)
            : (this.isValidLogoLink = S25Util.isValidUrl(this.logoLink));
        this.cd.detectChanges();
    }

    onChangeMsg(event: any, name: string) {
        name === "signInMsg" ? (this.signInMsg = event) : "";
        this.cd.detectChanges();
    }

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.getPrefs();
        this.cd.detectChanges();
    }

    getPrefs() {
        return S25Util.all({
            prefs: PreferenceService.getPreferences(
                [
                    "config_login_required",
                    "config_search_user",
                    "config_chatbot_name",
                    "config_login_msg",
                    "config_logo_link",
                    "config_ev_display",
                    "Eval25",
                ],
                "S",
            ),
            eval25: Eval25Service.instanceHasEval25(),
        }).then((resp) => {
            this.hasEval25License = resp.eval25;
            this.initSearchUser = this.searchUser = resp.prefs.config_search_user.value;
            this.initAiChatbotName = this.aiChatbotName = resp.prefs.config_chatbot_name.value;
            this.initLogoLink = this.logoLink = resp.prefs.config_logo_link.value;
            this.initLoginRequired = this.loginRequired = S25Util.toBool(resp.prefs.config_login_required.value);
            this.eval25Display = this.initEval25Display = S25Util.toBool(resp.prefs?.Eval25?.value);
            resp.prefs.config_ev_display.value === "name" ? (this.eventDisplay = true) : (this.eventDisplay = false);
            this.initEventDisplay = this.eventDisplay;

            if (resp.prefs.config_login_msg && resp.prefs.config_login_msg.value) {
                this.initSignInMsg = this.signInMsg = S25Util.unescapeXml(resp.prefs.config_login_msg.value);
                // this.signInMsg = this.sanitizer.sanitize(SecurityContext.HTML, S25Util.unescapeXml(
                //     S25Util.numericEntityToChar(S25Util.unicodeToChar(((resp.prefs.config_login_msg.value || "") + ""), true), true)));
            }

            this.initLoading = false;
            this.cd.detectChanges();
        });
    }

    save() {
        this.loading = true;
        this.cd.detectChanges();
        let eventDisplay = "name";
        this.eventDisplay === false ? (eventDisplay = "title") : "";

        let promiseArr = [];
        this.initLoginRequired !== this.loginRequired &&
            promiseArr.push(PreferenceService.setPreference("config_login_required", this.loginRequired, "S"));
        this.initSignInMsg !== this.signInMsg &&
            promiseArr.push(
                PreferenceService.setPreference(
                    "config_login_msg",
                    S25Util.escapeXml(
                        S25Util.numericEntityToChar(S25Util.unicodeToChar((this.signInMsg || "") + "", true), true),
                    ),
                    "S",
                ),
            );
        this.initSearchUser !== this.searchUser &&
            promiseArr.push(PreferenceService.setPreference("config_search_user", this.searchUser, "S"));
        this.initAiChatbotName !== this.aiChatbotName &&
            promiseArr.push(PreferenceService.setPreference("config_chatbot_name", this.aiChatbotName, "S"));
        this.initLogoLink !== this.logoLink &&
            promiseArr.push(PreferenceService.setPreference("config_logo_link", this.logoLink, "S"));
        this.initEventDisplay !== this.eventDisplay &&
            promiseArr.push(PreferenceService.setPreference("config_ev_display", eventDisplay, "S"));
        this.initEval25Display !== this.eval25Display &&
            promiseArr.push(PreferenceService.setPreference("Eval25", this.eval25Display, "S"));

        if (promiseArr) {
            return S25Util.all(promiseArr).then((resp) => {
                this.loading = false;
                this.message = "Success!";
                this.cd.detectChanges();
            });
        }
    }

    cancel() {
        this.initLoading = true;
        this.cd.detectChanges();
        this.getPrefs();
        this.initLoading = false;
        this.isValidLogoLink = true;
        this.cd.detectChanges();
    }
}
