//@author: sara
import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { S25Durationfilter } from "../modules/s25-editable/s25-editable-duration/s25.durationfilter.service";
import { S25Datefilter } from "../modules/s25-dateformat/s25.datefilter.service";

@Pipe({
    name: "safeLink",
})
export class SafeLink implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(url: string): any {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
}

//we should be able to add more @pipe classes here
@Pipe({
    name: "durationPipe",
})
export class DurationPipe implements PipeTransform {
    transform(duration: any): string {
        return S25Durationfilter.duration(duration);
    }
}

@Pipe({
    name: "SortBy",
})
export class SortByPipe implements PipeTransform {
    transform(values: Array<any>, args?: string) {
        return args === "ASC" ? values && values.sort() : values && values.sort().reverse();
    }
}

@Pipe({
    name: "dateFormat",
})
export class DateFormatPipe implements PipeTransform {
    transform(date: any, format?: string) {
        return S25Datefilter.transform(date, format);
    }
}

@Pipe({
    name: "timeFormat",
})
export class TimeFormatPipe implements PipeTransform {
    transform(date: any, format?: string) {
        return S25Datefilter.transform(date, format);
    }
}

// keeps HTML sanitizer from removing CSS from rich text editor contents
@Pipe({
    name: "safeHTML",
    pure: false,
})
export class HTMLPipe implements PipeTransform {
    constructor(protected _sanitizer: DomSanitizer) {}
    transform(items: any): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(items);
    }
}

@Pipe({
    name: "currency",
})
export class CurrencyFormatPipe implements PipeTransform {
    transform(number: number) {
        const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        });

        return formatter.format(number);
    }
}
